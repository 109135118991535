<template>
  <div class="containertop-register">
    <nav-bar :title="$t('認證資料')" :isFixed="true" :goBack="goBack" :bgc="'background: rgba(224, 239, 201)'">
      <template v-slot:right>
        <div v-if="isdisplay" class="skip" @click="goLogin"> {{ $t('跳過並直接登錄') }}</div>
      </template>
    </nav-bar>
    <div class="bg"></div>
    <div :class="['register-form']">
      <van-form @submit="getSubmitRegister">
        <div class="label" style="margin-top: 0;">
          {{ $t("診所名稱") }}
        </div>
        <van-field v-model="params.userName" :placeholder="$t('診所名稱')" />
        <div class="error" v-if="verifyobj.userName">
          {{ $t("请填写診所名稱") }}
        </div>
        <div class="label">{{ $t("姓名") }} </div>
        <van-field v-model="params.name" :placeholder="$t('姓名')" />
        <div class="error" v-if="verifyobj.name">
          {{ $t("請輸入") }}{{ $t("姓名") }}
        </div>
        <div class="label">
          {{ $t("診所地址") }}
        </div>
        <div class="AddressList" @click="openAddressDialog">
          <div :style="selectTitle ? 'color:#1A1A1A;' : ''">
            {{ selectTitle ? selectTitle : $t("所在地區") }}
          </div>
          <img src="@/assets/icons/arrow-rightactive.png" alt="" />
        </div>
        <div class="error" v-if="verifyobj.province" style="margin-top: 6px;">
          {{ $t("請選擇") }}{{ $t("所在地區") }}
        </div>
        <van-field style="margin-bottom: 12px;margin-top: 12px;" v-model="params.street"
          :placeholder="$t('大廈/屋苑&街道')" />
        <div class="error" v-if="verifyobj.street" style="margin-top: 6px;">
          {{ $t("請輸入") }}{{ $t("大廈/屋苑&街道") }}
        </div>
        <van-field v-model="params.address" :placeholder="$t('單位&樓層')" />
        <div class="error" v-if="verifyobj.address" style="margin-top: 6px;">
          {{ $t("請輸入") }}{{ $t("單位&樓層") }}
        </div>
        <van-checkbox v-if="ishidden" checked-color="#90D12E" icon-size="16px" shape="square" v-model="saveaddress">{{ $t("保存為收貨地址")
          }}</van-checkbox>
        <div class="label">
          {{ $t("商業登記證") }}
        </div>
        <van-uploader :max-count="1" :before-read="beforeRead" :after-read="afterRead" v-model="fileList"
          preview-size="100%">
          <template #default>
            <div v-if="!loading2">
              <img v-if="langEnv == 2" src="@/assets/image/user/uploaden.png" alt="" />
              <img v-else src="@/assets/image/user/uploadzh.png" alt="" />
            </div>
            <van-loading style="width: 100%;height: 100%;" v-if="loading2">

            </van-loading>
          </template>
        </van-uploader>
        <div class="error" v-if="verifyobj.businessLicense">
          {{ $t("请填写") }} {{ $t("商業登記證") }}
        </div>

        <div style="margin: 12px 0;">
          <van-button :loading="loading" color="#90D12E" block type="primary" native-type="submit">
            {{ isdisplay ? $t("提交並登錄") : $t("提交") }}
          </van-button>
        </div>
      </van-form>
    </div>
    <addressCascader :isShow="isShow" @handleClose="handleClose" @handleAreaCode="handleAreaCode"></addressCascader>
    <van-popup v-model:show="show" position="bottom" :close-on-click-overlay="false"
      :style="{ height: '40%',borderRadius: '16px 16px 0 0' }">
      <div class="titleArea">
        <div></div>
        <div>{{ $t('國家地區') }}</div>
        <div class="close">
          <img @click="show = false" style="width: 100%" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
      </div>
      <div class="phone-list">
        <div :class="['phone-item',{ actvie: item.code == params.areaCode }]" v-for="item in phoneCode" :key="item.code"
          @click="handleAreaCode2(item)">
          <div class="phone-text">{{ item.name }}</div>
          <img class="phone-check" v-if="item.code == params.areaCode" src="@/assets/icons/check.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import heic2any from "heic2any"

import { registerNewLogin, getRegisterNew, registerNewAuth } from "@/api/user"
import { setToken } from "@/utils/token.js"
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
export default {
  mixins: [mixins],
  data () {
    return {
      fileList: [],
      show: false,
      phoneLabel: '',
      params: {
        userName: "",
        password: "",
        email: "",
        platform: 4,
        name: "",
        address: "",
        captcha: "",
        phone: "",
        province: "",
        city: "",
        district: "",
        street: "",
        businessLicense: "",
        areaCode: "",
        invitationCode: ""
      },
      AddressList: [],
      isShow: false,
      selectTitle: "",
      verifyobj: {
        userName: "",
        // password: "",
        name: "",
        // email: "",
        businessLicense: "",
        province: "",
        address: "",
        street: "",
        // phone: ""
      },
      verifyList: [],
      loading: false,
      loading2: false,
      langEnv: "",
      saveaddress: false,
      isdisplay: true,
      ishidden:true
    }
  },
  computed: {
    ...mapState("user", ["registerForm", "cacheFileList", "phoneCode","userInfo"])
  },
  watch: {
    params: {
      deep: true,
      async handler (val) {
        try {
          this.$store.dispatch("user/getregisterForm", this.params)
          this.$store.dispatch("user/getFileList", this.fileList)
        } catch (error) { }
      }
    }
  },
  async created () {
    if (this.$route.query?.qwe) {
      this.isdisplay = false
      if(this.userInfo.phone){
        this.saveaddress=true
      }else if(this.userInfo.email){
        this.saveaddress=false
        this.ishidden =false
      }
    } else {
      this.isdisplay = true
      this.params = { ...this.registerForm }
      if(this.params.phone){
        this.saveaddress=true
        this.ishidden =true
      }else if(this.params.email){
        this.saveaddress=false
          this.ishidden =false
      }
    }
    await this.$store.dispatch("user/getPhoneCode")
    
    this.params.areaCode = this.phoneCode[0]?.code
    this.phoneLabel = this.phoneCode[0]?.name
    this.langEnv = window.localStorage.getItem("langEnv")
    this.fileList = this.cacheFileList
    this.selectTitle = `${this.params?.province?.split("●")[1] || ''}${this.params?.city?.split("●")[1]
      ? "/" + this.params?.city?.split("●")[1]
      : ""
      }${this.params?.district?.split("●")[1]
        ? "/" + this.params?.district?.split("●")[1]
        : ""
      }`

    // this.getAddressList();
  },

  methods: {
    // 直接登录
    async goLogin () {
      if (this.$route.query.invitationCode) {
        this.params.invitationCode = this.$route.query.invitationCode
      }

      let formData = new FormData()
      Object.entries({ ...this.params }).forEach(([key, value]) => {
        formData.append(key, value)
      })
      this.loading = true
      const result = await registerNewLogin(formData)
      if (result.resultID === 1200) {
        // this.$toast(result.message)
        setToken(result.data.token)
        localStorage.setItem("userName", result.data.userName)
        localStorage.setItem("roles", result.data.roles)
        this.$router.push("/home")
      }
      this.loading = false
    },
    handleAreaCode2 (item) {
      this.show = false
      this.params.areaCode = item.code
      this.phoneLabel = item.name
    },
    goBack () {
      this.$router.back()
    },
    compressImg (fileObj) {
      return new Promise((resolve, reject) => {
        let quality = 0.99
        let reader = new FileReader()
        reader.readAsDataURL(fileObj)
        reader.onload = function (e) {
          let image = new Image()
          image.src = e.target.result
          image.onload = function () {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let imageWidth = image.width / 2
            let imageHeight = image.height / 2
            canvas.width = imageWidth
            canvas.height = imageHeight

            context.drawImage(image, 0, 0, imageWidth, imageHeight)
            let data = canvas.toDataURL('image/jpg', quality)
            while (data.length / 1024 / 1024 > 5) {
              quality -= 0.03
              context.clearRect(0, 0, canvas.width, canvas.height) // 清空画布
              context.drawImage(image, 0, 0, canvas.width, canvas.height) // 重新绘制图像
              data = canvas.toDataURL("image/jpeg", quality) // 重新生成数据URL
            }

            canvas.toBlob(function (blob) {
              const f = new File([blob], fileObj.name, { type: 'image/jpeg' })
              resolve(f)
            }, 'image/jpeg')
          }
          image.onerror = function () {
            reject(new Error("Image loading error"))
          }
        }
        reader.onerror = function () {
          reject(new Error("File reading error"))
        }
      })
    },
    async beforeRead (file) {
      const maxSize = 10 * 1024 * 1024
      const maxSize2 = 5 * 1024 * 1024

      this.loading2 = true
      // 文件类型检查
      // if (!file.type?.includes('image')) {
      // this.loading2=false;
      //   this.$toast("请上传图片");
      //   return false;
      // }
      if (file.size > maxSize) {
        this.$toast("文件體積過大,請小于10mb")
        return false
      }
      if (file.type.includes('heic')) {
        const resultBlob = await heic2any({
          blob: file,
          toType: "image/jpeg"
        })
        file = new File([resultBlob], 'newFileName.' + file.name.split('.').pop(), { type: file.type })
      }
      return this.compressImg(file)
        .then(compressedFile => {
          return compressedFile // 返回压缩后的文件
        })
        .catch(err => {
          console.error(err)
          return false // 处理错误情况
        })

    },
    validatoForm () {
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
    },
    afterRead (file) {
      this.params.businessLicense = file.file
      this.loading2 = false
      this.$nextTick(() => {
        this.fileList = [{ ...file, isImage: true }]
      })
    },
    handleAreaCode (data) {
      const areaList = data.filter(item => item.value !== "1")
      areaList.forEach((item, index) => {
        if (index === 0) {
          this.params.province = item.value + "●" + item.label
        } else if (index === 1) {
          this.params.city = item.value + "●" + item.label
        } else if (index === 2) {
          this.params.district = item.value + "●" + item.label
        }
      })
      const res = areaList.map(items => items.label).join("/")
      this.selectTitle = res
    },
    // 验证
    handleExamine () {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      let regxPhone = /^1[3456789]\d{9}$/
      switch (this.params.areaCode) {
        case '+852': // 香港
          regxPhone = /^(?:\+852)?[0-9]{8}$/
          break
        case '+86': // 中国大陆
          regxPhone = /^(?:\+86)?1[3-9][0-9]{9}$/ // 11位手机号，以1开头
          break
        case '+1': // 美国/加拿大
          regxPhone = /^(?:\+1)?[0-9]{10}$/ // 10位手机号
          break
        case '+853': // 澳门
          regxPhone = /^(?:\+853)?[0-9]{8}$/ // 8位手机号
          break
        // 可以添加其他国家的区号和对应的正则表达式
        default:
          regxPhone = /^(?:\+852)?[0-9]{8}$/
      }
      this.verifyList = []

      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
    },
    async getSubmitRegister () {
      const isSubmit = await this.handleExamine()
      if (!isSubmit) return
      if (this.$route.query.invitationCode) {
        this.params.invitationCode = this.$route.query.invitationCode
      }

      let formData = new FormData()
      Object.entries({ ...this.params }).forEach(([key, value]) => {
        formData.append(key, value)
      })
      if (this.saveaddress) {
        formData.append('saveaddress', 1)
      }
      // formData.append("businessLicense", this.params.businessLicense);
      this.loading = true
      if (this.isdisplay) {
        const res = await getRegisterNew(formData)
        if (res.resultID === 1200) {
          this.$toast(res.message)
          setToken(res.data.token)
          localStorage.setItem("userName", res.data.userName)
          localStorage.setItem("roles", res.data.roles)
          this.$router.push("/home")
        }
      } else {
        if (this.userInfo.phone) {
          formData.append('phone', this.userInfo.phone)
        }
        if (this.userInfo.email) {
          formData.append('email', this.userInfo.email)
        } 
        const res = await registerNewAuth(formData)
        if (res.resultID === 1200) {
          this.$toast(this.$t('操作成功'))
          this.$router.back()
        }
      }

      this.loading = false
    },
    openAddressDialog () {
      this.isShow = true
    },
    handleClose (data) {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss">
.containertop-register {
  .email-CaptchaNew {
    padding: 40px 26px;

    .CaptchaNew-title {
      font-size: 15px;
      color: #666666;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .get-CaptchaNew {
      text-align: center;
      padding: 10px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #d2edab;
      color: #bce382;
      margin-bottom: 40px;
    }

    .get-CaptchaNew-btn {
      width: 100%;
      background: #90d12e;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
    }
  }

  .bg {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 148px;
    background: linear-gradient(180deg, rgba(144, 209, 46, 0.3) 0%, rgba(144, 209, 46, 0) 100%);
    border-radius: 0px 0px 0px 0px;
  }

  .logo {
    width: 166px;
    margin: 46px 0 40px 32px;
  }

  .register-form {
    padding: 68px 26px 0;

    .label {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 17px;
      color: #1a1a1a;
      line-height: 24px;
      margin-top: 24px;
    }

    .van-checkbox {
      align-items: start;

      .van-checkbox__icon {
        margin-top: 3px;

        .van-badge__wrapper {
          border-radius: 2px;
        }
      }
    }

    .agree {
      font-size: 14px;

      .agree-text {
        color: #90d12e;
      }
    }

    .van-uploader,
    .van-uploader__input-wrapper {
      width: 100%;

      .van-uploader__preview-image {
        height: 216px !important;
        object-fit: cover;
      }

      .van-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 216px !important;
      }
    }

    .van-button {
      border-radius: 12px;
    }

    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }
  }

  .verify {
    color: #f82207;
  }

  .van-field {
    background-color: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ebebeb;
    margin-bottom: 12px;
    padding: 16px;

    .van-field__control {
      font-size: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;

      &::placeholder {
        color: #999999;
      }
    }
  }

  .m12 {
    margin-bottom: 12px;
  }

  .van-tabs {
    width: 60%;
  }

  .van-tabs__nav {
    background-color: transparent;
    height: auto;

    .van-tabs__line {
      display: none;
    }

    .van-tab:first-child {
      border-right: 1px solid #bdbdbd;
    }

    .van-tab__text {
      font-size: 18px;
    }
  }

  .arrow {
    width: 16px;
    height: 16px;
  }

  .phone {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #1a1a1a;
    margin: 12px 0px 20px 14px;
  }

  .login-form {
    padding: 0 22px;
  }
}

.Forgot {
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 20px;
}

.register {
  text-align: center;
  color: #90d12e;
}

.AddressList {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-size: 16px;
  background-color: #fff;

  // margin-bottom: 6px;
  img {
    width: 20px;
  }
}

.register-dialog {
  padding: 24px;
}

.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;

  span {
    color: #3491fa;
  }
}

.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }

  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }

  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}

.phone-list {
  padding: 20px 16px;

  .actvie {
    border: 2px solid #90d12e !important;
  }

  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;

    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }

    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}

.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}

.skip {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #3491FA;
  line-height: 24px;
}
</style>
